export const STRINGS = {
  FONTS: {
    HELVITICA_BOLD: "Helvetica bold",
    HELVITICA_LIGHT: "Helvetica light",
    HELVITICA: "Helvetica",
    POPPINS: "poppins",
  },
  ROUTES: {
    LOGIN: "/",
    TABLES: "/tables",
    RTL: "/rtl",
    DASHBOARD: "/dashboard",
    PROFILE: "/profile",
    FORMS: "/forms",
    RATINGS: "/ratings",
    APPROVAL: "/approval",
    CONCEPT_APPROVAL_TABLE: "/concept-approval-table",
    INFLUENCER_APPROVAL_TABLE: "/influencer-approval-table",
    INFLUENCER: "/influencer",
    INFLUENCER_VIEW: "/influencer",
    CONCEPT: "/concept",
    CONCEPT_VIEW: "/concept",
    REQUEST: "/request",
    WITHDRAWAL: "/withdrawal",
    PAYMENTS: "/payment",
    SUPPORT: "/support",
    SETTING: "/setting",
    NOTIFICATION: "/notification",

  },
  apiUrl: (window.location.host).includes('localhost') ? "http://[::1]:3000/api/" : (window.location.host).includes('dev') ? "https://dev-api.inspooo.com/api/" : ((window.location.host).includes('admin') ? "https://api.inspooo.com/api/" : ''),
  dateFormat: "DD/MM/YYYY hh:mm a",
  requestStatusString: {
    "active": "Influencer wants to cover",
    "accept__active": "Concept accepted the request and created a coverage requirement",
    "reject": "Concept rejected it",
    "accept__accept": "Influencer accepted the requirement",
    "accept__reject": "Influencer declined the requirement",
    "accept__completed": "Influencer has covered it. Waiting for Concept's response",
    "accept__not_completed": "Influencer has not covered it. Waiting for Concept's response",
    "completed__completed": "Influencer has covered it. Concept has given a rating",
    "not_completed__completed": "Influencer has covered it, but Concept marked it as not covered",
    "not_completed__not_completed": "Influencer has not covered it. Concept has given a rating",
    "not_completed__reject": "Influencer has marked it as 'CAN'T COVER IT'",
    "not_completed__reject__no_action": "Influencer has not responded to Concept's requirement, it is automatically declined",
    "not_completed__accept": "not_completed_accept",
    "reject__completed": "reject_completed"
  }

};
